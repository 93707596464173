// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.I6oNzSPDhpJS2wyDNjiw{display:flex;justify-content:space-between;align-items:center;align-self:center;position:relative;gap:36px}.I6oNzSPDhpJS2wyDNjiw>*{flex:0 0 auto}.I6oNzSPDhpJS2wyDNjiw>ul{top:32px;right:0;margin:0;text-align:left}.I6oNzSPDhpJS2wyDNjiw>ul span{line-height:1.8rem;cursor:pointer}.I6oNzSPDhpJS2wyDNjiw a.EtTDb0otiKrRe1dED53r .OAkoTkzGn68g8rzQcmlg{margin-right:8px;max-width:25vw;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}@media(max-width: 1024.02px){.I6oNzSPDhpJS2wyDNjiw>span{display:none}}`, ""]);
// Exports
export var customerPickerEndContainer = `I6oNzSPDhpJS2wyDNjiw`;
export var customerPickerSelect = `EtTDb0otiKrRe1dED53r`;
export var customerPickerSelectName = `OAkoTkzGn68g8rzQcmlg`;
export default ___CSS_LOADER_EXPORT___;
